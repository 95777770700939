<template>
  <div class="cambio-sede mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>Cambiar de sede</v-card-title>
          <v-card-subtitle
            >Desde este apartado podras cambiar entre las diferentes recepciones
            de tu centro.</v-card-subtitle
          >
          <v-card-text>
            <v-row dense>
              <v-col
                cols="12"
                md="6"
                sm="6"
                v-for="(item, index) in lista_sedes"
                :key="index"
              >
                <v-card outlined>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        {{ item.direccion }}
                      </div>
                      <v-list-item-title class="text-h5 mb-1">
                        {{ item.sede }}
                      </v-list-item-title>
                      <v-list-item-subtitle> </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-card-actions>
                    <v-btn text color="success" @click="cambiarSede(item)">
                      Cambiar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    sedes: [],
  }),
  methods: {
    loadSedes() {
      const body = {
        route: "/sedes",
      };
      this.$store
        .dispatch("axios_get", body)
        .then((response) => {
          if (response.data.success) {
            this.sedes = response.data.data;
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.dispatch("logout", this.$store.state.access_token);
          }
        });
    },
    cambiarSede(item){
     const body = {
       route: '/change_sede',
       data:{
         id: item.id
       }
     }
     this.$store.dispatch('axios_post', body).then(response => {
       if(response.data.success){
         window.location.reload()
       }
     })
    }
  },
  created() {
    this.loadSedes();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["callc"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista_sedes() {
      const sd = this.$store.state.centro.sd;
      if (sd != null) {
        return this.sedes.filter((sede) => {
          return sede.id != sd;
        });
      } else return []
    },
  },
};
</script>
